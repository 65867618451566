import React from 'react'
import InputComponent from '../components/InputComponent'
import CheckboxComponent from '../components/CheckboxComponent'
// import Logo from '../images/logo.svg'
import '../layouts/index.scss'
import {sundialURL, listId, confirmOver18Conditions, emailValidation, cannotBeEmptyValidationConditions, newsLetterEmailValidationConditions} from '../constants/constants'
import axios from 'axios'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.png'
import Header from '../components/Header'
import Slider from '../components/Slider'
import LegalizationImage from '../images/Legalization_Header.svg'
import ProductImage from '../images/product_icon.png'
import EaseImage from '../images/ease-icon.png'
import EdibleIcon from '../images/edible_icon.svg'
import JointIcon from '../images/joint_icon.svg'
import VapeIcon from '../images/vape_icon.svg'
import OilIcon from '../images/oil_icon.svg'
// import TrackIcon from '../images/trackIcon.svg'
import TrackIcon from '../images/StopwatchJournal_White.svg'
import StartLowImage1 from '../images/start_low_image_1.png'
import StartLowImage2 from '../images/start_low_image_2.png'
import SativaIcon from '../images/sativa_icon.svg'
import HybridIcon from '../images/hybrid_icon.svg'
import IndicaIcon from '../images/indica_icon.svg'
import CannabinoidsGraphic from '../images/cannabinoids_graphic.png'
import BrainIcon from '../images/brain_icon.svg'
import THCIcon from '../images/thc_icon.svg'
import CBDIcon from '../images/cbd_icon.svg'
import IngestibleIcon1 from '../images/ingesting_icon_1.png'
import IngestibleIcon2 from '../images/ingesting_icon_2.png'
import IngestibleIcon3 from '../images/ingesting_icon_3.png'
import JournalIcon from '../images/journal_icon.svg'
import FacebookIcon from '../images/facebook_icon.svg'
import TwitterIcon from '../images/twitter_icon.svg'
import InstagramIcon from '../images/instagram_icon.svg'
import AgeVerification from '../components/AgeVerification';
export default class IndexPage extends React.Component {
  state = {
    triggerBeSafe: false,
    triggerPhysio: false,
    windowWidth: typeof window !== 'undefined' ? window.innerWidth : 1099,
    success: false,
    loading: false,
    invalidFields: [],
    errorResponse: '',
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    city: '',
    ageVerified: false,
    receiveMarketing: false,
    province: '',
    postalCode: ''
  }
  componentDidMount() {
    this.getSize()
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.changeWidth);
  }
  componentWillUnmount()  {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.changeWidth);
  }
  getSize = () => {
    this.setState({
      windowWidth: window.innerWidth
    })
  }
  changeWidth = (e) => {
    this.setState({
      windowWidth: window.innerWidth
    })
  }
  onScroll = (e) => {
    if(window.pageYOffset > document.getElementById('be-safe').offsetTop - 320) {
      this.setState({
        triggerBeSafe: true,
      })
    }
    if(window.pageYOffset > document.getElementById('physio-strain').offsetTop - 200) {
      this.setState({
        triggerPhysio: true
      })
    }
  }
  onChange = (e) => {
    let val;
    if(e.currentTarget.value !== undefined) { val = e.currentTarget.value; }
    else { val = e.currentTarget.checked }
    if(e.currentTarget.value === 'on' || e.currentTarget.value === 'off') {
      val = e.currentTarget.checked
    }
    this.setState( {
      [e.currentTarget.id]: val,
      invalidFields: [],
      errorResponse: ''
    })
  }
  onSubmit = (e) => {
    this.setState({
      submitting: true,
      autoValidate: true
    })
    e.preventDefault()
    
    if(emailValidation(this.state.email) && this.state.ageVerified && this.state.firstName !== "" && this.state.lastName !== "" && this.state.province !== "" && this.state.city !== "" && this.state.postalCode !== "" && this.state.address !== '') {
      this.setState({
        loading: true,
        invalidFields: [],
        showAgeValidationMessage: false
      })
      const self = this
      const url = `${sundialURL}`
      const body = {
        "email": this.state.email,
        "listId": listId,
        "mergeFields": {
          "FNAME": this.state.firstName,
          "LNAME": this.state.lastName,
          "ADDRESS": this.state.address,
          "CITY": this.state.city,
          "PROVINCE": this.state.province,
          "POSTALCODE": this.state.postalCode,
          "MARKETING": this.state.receiveMarketing ? 'true' : 'false'
        },
        "subscribe": this.state.receiveMarketing
      }
      axios.post(url, body).then((response) => {
        if(response.data !== undefined && response.data.success === "true") {
          self.setState({
            success: true,
            loading: false,
            disabled: true,
            errorResponse: ''
          })
          window.setTimeout(() => {
            self.setState({
              disabled: false,
              showSuccess: true
            })
          }, 700)
        } else {
          self.setState({
            success: false,
            isLoading: false,
            errorResponse: 'There was an error submitting your form. Please make sure the fields are filled out correctly and try again.'
          })
        }
      }).catch((error) => {
        self.setState({
          success: false,
          loading: false,
          errorResponse: 'There was an error submitting your form. Please make sure the fields are filled out correctly and try again.'
        })
      })
    } else {
      //Handle invalid fields;
      let fields = [];
      if(this.state.firstName === '') {
        fields.push('firstName');
      }
      if(this.state.lastName === '') {
        fields.push('lastName');
      }
      if(!emailValidation(this.state.email)) {
        fields.push('email');
      }
      if(this.state.city === '') {
        fields.push('city');
      }
      if(this.state.address === '') {
        fields.push('address');
      }
      if(this.state.postalCode === '') {
        fields.push('postalCode');
      }
      if(this.state.province === '') {
        fields.push('province');
      }
      this.setState({
        loading: false,
        success: false,
        invalidFields: fields,
        errorResponse: 'There are some invalid fields or some fields have not been completed. Please fill out fields'
      })
    }
  }
  render() {
    const knowledge = [{
      title: "SATIVA",
      description: "Sativa plants typically have thin leaves and grow tall. Sativa strains are thought by many to be energizing and uplifting. ",
      url: SativaIcon,
      class: 'sativa'
    }, {
      title: "INDICA",
      description: "Indica plants typically have thick leaves and grow short and full. Indica strains are thought by many to be more relaxing and sedating. ",
      url: IndicaIcon,
      class: 'indica'
    }, {
      title: "HYBRID",
      description: "Hybrids combine sativa and indica genetics. These plants can have characteristics of either or both plant types. ",
      url: HybridIcon,
      class: 'hybrid'
    }]
    const ingestible = [{
      title: "INHALING VS. INGESTING",
      description: "Today there are a lot of options when it comes to consuming your cannabis. ",
      class: "info-slide-block"
    }, {
      title: "SMOKE IT",
      description: "If you're a traditionalist, rolling a joint or using a pipe is a quick and easy option. The effects of smoked cannabis are generally felt within a few minutes and last for a few hours. ",
      class: "slide-block"
    }, {
      title: "PUT IT UNDER YOUR TONGUE",
      description: "Cannabis oils can be consumed orally, or added to juice/coffee/etc. When you're ingesting cannabis, the full effects can take up to two hours to kick in and can last as long as 10 hours. ", 
      class: "slide-block"
    },
    {
      title: "VAPOURIZE IT",
      description: "Using a vapourizer is similar to smoking but without the combustion. Vapourizers come in many sizes and can cost anywhere from around $60 to $500. ",
      class: "slide-block"
    }, {
      title: "EAT IT",
      description: "Selling edibles is not legal in Canada, but you can make your own using cannabis or cannabis oils. Be sure to do your research first on preparing foods with cannabis, and take care to ensure nobody unwittingly eats it. ",
      class:"slide-block"
    }]
    return (
      <div className="content__container">
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-78976081-2"></script>
        <script dangerouslySetInnerHTML={{__html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-78976081-2');`}}>
        
        </script>

        <Helmet
          title="Start Low Go Slow "
          meta={[
            {name: 'description', content: 'Start Low Go Slow '},
            {name: 'keywords', content: 'Start Low Go Slow , Quality Cannabis, Sundial Growers, Sundial Cannabis'}
          ]}
          defaultTitle="Start Low Go Slow "
          titleTemplate="Start Low Go Slow "
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
          ]}
          />
          <Header />
          <AgeVerification />
          <section className='legalization-container'>
            <img src={LegalizationImage} alt="legalization"></img>
            <h1>LEGALIZATION IS HERE</h1>
            <h3>RECREATIONAL, ADULT-USE CANNABIS IS NOW LEGAL IN CANADA.</h3>
          </section>
          <section className='ready-container'>
            <h3>ARE YOU READY?</h3>
            <p>It's 2018—we aren't talking about your mother's weed anymore. Things have changed over the last decade, and for many, cannabis is part of an active, healthy lifestyle. Whether you're new to cannabis, are an experienced consumer, or somewhere in between, it's time to familiarize yourself with the modern landscape.</p>
            
          </section>
          <section id='be-safe' className='be-safe-container'>
            <img src={ProductImage} alt="product" ></img>
            <h1>BE SAFE</h1>
            <p>There has never been a recorded death from a cannabis overdose. That said, it's still important to be safe. "Start low, go slow" has become the mantra of the Canadian cannabis industry--it's good advice, and it makes sure your safety comes first.</p>
            <div className={`dots-down ${this.state.triggerBeSafe ? 'animateDown' : ''}`}></div>
            <h3 className={this.state.triggerBeSafe ? 'animateFade' : ''}>DOSING CANNABIS PROPERLY</h3>
            <p className={this.state.triggerBeSafe ? 'animateFade' : ''}>There are no firm rules when it comes to finding the right cannabis dose because there are so many factors that can impact cannabis's effects on you.</p>
          </section>
          <section id='physio-strain' className='physio-strain-container'>
            {this.state.triggerPhysio && <div className='top-start'>
              <div className='draw'></div>

            </div>}
            {this.state.triggerPhysio && <div className='left-start'>
              <div className='draw'></div>
            </div>}
            {this.state.triggerPhysio &&  <div className='right-down'>
              <div className='draw'></div>
            </div>}
            {this.state.triggerPhysio && <div className='left-small'>
              <div className='draw'></div>
            </div>}
            {this.state.triggerPhysio && <div className='left-large'>
              <div className='draw'></div>
            </div>}
            {this.state.triggerPhysio && <div className='left-down'>
              <div className='draw'></div>
            </div>}
            {this.state.triggerPhysio && <div className='right-large'>
              <div className='draw'></div>
            </div>}

            <div className='physio-container'>
              <h1>PHYSIOLOGY</h1>
              <p>Everybody is different, and people's bodies can resond differently. In fact, your own body's response to cannabis can change based on factors like what you've eaten today.</p>
            </div>
            <div className='strain-container'>
              <h1>STRAIN</h1>
              <p>Each strain has its own blend of cannabinoids and terpenes, which means each strain interacts with your body differently.</p>
            </div>
            <div className='ease-image show-container-tablet'>
              <img src={EaseImage} alt="ease-in"></img>
            </div>
            <div className='tip-container show-container-tablet-flex'>
              <div className='tip-pulse'>
                <p>TIP</p>
              </div>
              <div className='dots-left'></div>
              <p>The effects of inhaled cannabis are generally felt within a few minutes but the effects of ingested cannabis can take up to two hours to reach full effect</p>
            </div>
             <div className='tip-container show-container-desktop-flex'>
              <div className='tip-pulse'>
                <p>TIP</p>
              </div>
              <div className='dots-left'></div>
              <p>The effects of inhaled cannabis are generally felt within a few minutes but the effects of ingested cannabis can take up to two hours to reach full effect</p>
            </div>
            <div className='ease-image show-container-desktop'>
              <img src={EaseImage} alt="ease-in"></img>
            </div>
            <div className='form-container'>
              <h1>FORM</h1>
              <p>Inhaling cannabis and ingesting cannabis can have very different results. For example, inhaling hits faster, ingesting lasts longer.</p>
              <div className='ingestible-container'>
                <img src={VapeIcon} alt="vape_icon"></img>
                <img src={JointIcon} alt="joint_icon"></img>
                <p>VS.</p>
                <img src={OilIcon} alt="oil_icon"></img>
                <img src={EdibleIcon} alt="edible_icon"></img>
              </div>
            </div>
            <div className='experience-container'>
              <h1>EXPERIENCE</h1>
              <p>Tolerance levels can change as time passes, meaning cannabis effects are different for experienced consumers versus new users. </p>
            </div>
          </section>
          <section id='start-low' className='start-low-container'>
            <div className='start-low-images-container'>
              <img src={StartLowImage1} alt="start-low-1"></img>
              <img src={StartLowImage2} alt="start-low-2"></img>
            </div>
            <h1>START LOW, GO SLOW</h1>
            <p>The best approach is low and slow.</p>
            <div className='start-low-blocks'>
              <div className='track show-container-tablet-flex'>
                <div className='dots-down'></div>
              </div>
              <div className='start-low'>
                <h3>START LOW</h3>
                <p>If you're new to cannabis, or if you're trying a new and potentially more potent strain for the first time, it's best to start with a small dose.</p>
              </div>
              <div className='track show-container-desktop'>
                <div className='dots-down'></div>
                <img src={TrackIcon} alt="track"></img>
              </div>
              
              <div className='start-low'>
                <h3>GO SLOW</h3>
                <p>Increase your dosage slowly and pay attention to how it makes you feel. And if you're ingesting cannabis oil or an edible, it's best to wait at least two hours to see what the full effects are before taking more. </p>
              </div>
              <div className='track show-container-tablet'>
                <img src={TrackIcon} alt="track"></img>
              </div>
            </div>
          </section>
          <section id='track-usage'>
            <h1>TRACK YOUR USAGE</h1>
            <p>Keep a journal of your cannabis use, making notes about strains, effects and flavours. It's a great way to learn what you like and what you don't.</p>
            <a href="/#journal" >Click here for a free Sundial tracking journal. </a>
          </section>
          <section id='know-your-cannabis'>
            <div className='know-container'>
              <h3>Know your cannabis</h3>
              <div className='classifications'>
              <div className='image-slider-container show-container-tablet-flex'><Slider>
                {knowledge.map((value, key) => {
                  return (
                    <div className={`${value.class} slide-item`} key={key}>
                      <img src={value.url} alt={`${value.title}`} ></img>
                      <h2>{value.title}</h2>
                      <p>{value.description}</p>
                    </div>
                  )
                })}
                </Slider></div>
                <div className='sativa show-container-desktop-flex'>
                    <img src={SativaIcon} alt="Sativa"></img>
                    <h2>SATIVA</h2>
                    <p>Sativa plants typically have thin leaves and grow tall. Sativa strains are thought by many to be energizing and uplifting. </p>
                </div>
                <div className='indica show-container-desktop-flex'>
                  <img src={IndicaIcon} alt="Indica"></img>
                  <h2>INDICA</h2>
                  <p>Indica plants typically have thick leaves and grow short and full. Indica strains are thought by many to be more relaxing and sedating. </p>
                </div>
                <div className='hybrid show-container-desktop-flex'>
                  <img src={HybridIcon} alt="hybrid"></img>
                  <h2>HYBRID</h2>
                  <p>Hybrids combine sativa and indica genetics. These plants can have characteristics of either or both plant types. </p>
                </div>
              </div>
            </div>
          </section>
          <section id='modern-cannabis'>
            <div className='text-chunk'>
              <h3>MODERN CANNABIS</h3>
              <p>Horticulture has come a long way, and while traditional thinking about the differences between sativas and indicas makes a good rule of thumb, there are plenty of examples of sativas with indicas effects and vice versa. With any new strain, remember to start low, go slow.</p>
            </div>
            <div className='cannabinoid-container show-container-tablet-flex'>

                  <div className='cbd-drop'>
                    <img src={CannabinoidsGraphic} alt="cannabinoids"></img>
                  </div>
                  <div className='texts-container'>
                    <div className='canna-title'>
                      <h1>CANNABINOIDS</h1>
                      <p>Cannabinoids are chemical compounds that occur naturally in cannabis. When consumed, these compounds interact with endocannabinoid receptors in the human body. There are many different cannabinoids, but the most important two are THC and CBD.</p>
                    </div>
                  </div>
                  <div className='chemical-container'>
                    <img src={THCIcon} alt="thc"></img>
                    <h1>THC</h1>
                    <p>THC is what delivers cannabis's intoxicating effects—it's the thing that makes you "high." All cannabis for sale in Canada will have clearly marked THC levels. The higher the level, the more potent the strain. If you're an inexperienced consumer, you should start with a THC concentration in the low teens or single digits. </p>
                  </div>
                  <div className='tip-container'>
                    <div className='tip-pulse'>
                      <p>TIP</p>
                    </div>
                    <div className='dots-left'></div>
                    <p>Start with a THC concentration in the single digits or low teens.</p>
                  </div>
                  <div className='chemical-container'>
                    <img src={CBDIcon} alt="cbd"></img>
                    <h1>CBD</h1>
                    <p>CBD is the most prevalent non-intoxicating cannabinoid. Despite having no immediately apparent effect, CBD is studied and often prescribed for its medicinal value, including as a possible option for people who suffer from pain, migraines, PTSD, ADHD, anxiety and more. </p>
                  </div>
                  <div className='brain-pulse'>
                    <img src={BrainIcon} alt="brain"></img>
                    <div className='orange-pulse'>
                      <div className='pulse-dot'></div>
                      <div className='pulse-item'></div>
                      <div className='pulse-item delay-pulse'></div>
                      <div className='pulse-item delay-pulse_strong'></div>
                    </div>
                  </div>
            </div>
            <div className='cannabinoid-container show-container-desktop-flex'>
              <div className='graphics-container'>
                <div className='cbd-drop'>
                  <img src={CannabinoidsGraphic} alt="cannabinoids"></img>
                </div>
                
                <div className='tip-container'>
                  <div className='tip-pulse'>
                    <p>TIP</p>
                  </div>
                  <div className='dots-left'></div>
                  <p>Start with a THC concentration in the single digits or low teens.</p>
                </div>
                <div className='brain-pulse'>
                  <img src={BrainIcon} alt="brain"></img>
                  <div className='orange-pulse'>
                    <div className='pulse-dot'></div>
                    <div className='pulse-item'></div>
                    <div className='pulse-item delay-pulse'></div>
                    <div className='pulse-item delay-pulse_strong'></div>
                  </div>
                </div>
              </div>
              <div className='texts-container'>
                <div className='canna-title'>
                  <h1>CANNABINOIDS</h1>
                  <p>Cannabinoids are chemical compounds that occur naturally in cannabis. When consumed, these compounds interact with endocannabinoid receptors in the human body. There are many different cannabinoids, but the most important two are THC and CBD.</p>
                </div>
                <div className='chemical-container'>
                  <img src={THCIcon} alt="thc"></img>
                  <h1>THC</h1>
                  <p>THC is what delivers cannabis's intoxicating effects—it's the thing that makes you "high." All cannabis for sale in Canada will have clearly marked THC levels. The higher the level, the more potent the strain. If you're an inexperienced consumer, you should start with a THC concentration in the low teens or single digits. </p>
                </div>
                <div className='chemical-container'>
                  <img src={CBDIcon} alt="cbd"></img>
                  <h1>CBD</h1>
                  <p>CBD is the most prevalent non-intoxicating cannabinoid. Despite having no immediately apparent effect, CBD is studied and often prescribed for its medicinal value, including as a possible option for people who suffer from pain, migraines, PTSD, ADHD, anxiety and more. </p>
                </div>
              </div>
            </div>
          </section>
          <section id='ingestible'>
            <div className='icon-container'>
              <img src={IngestibleIcon1} alt="ingestible-1"></img>
              <img src={IngestibleIcon2} alt="ingestible-2"></img>
              <img src={IngestibleIcon3} alt="ingestible-3"></img>
            </div>
            <Slider className='show-container-tablet'>
              {ingestible.map((value, key) => {
                return (
                  <div className={value.class} key={key}>
                    <h3>{value.title}</h3>
                    <p>{value.description}</p>
                  </div>
                )
              })}
            </Slider>
            <div className='info-container show-container-desktop-flex'>
              <h1>INHALING VS. INGESTING</h1>
              <p>Today there are a lot of options when it comes to consuming your cannabis. </p>
            </div>
            <div className='how-to-use-blocks show-container-desktop-flex'>
              <div className='column-row'>
                <div>
                  <h3>SMOKE IT</h3>
                  <p>If you're a traditionalist, rolling a joint or using a pipe is a quick and easy option. The effects of smoked cannabis are generally felt within a few minutes and last for a few hours. </p>
                </div>
                <div>
                  <h3>PUT IT UNDER YOUR TONGUE</h3>
                  <p>Cannabis oils can be consumed orally, or added to juice/coffee/etc. When you're ingesting cannabis, the full effects can take up to two hours to kick in and can last as long as 10 hours. </p>
                </div>
              </div>
              <div className='column-row'>
                <div>
                  <h3>VAPOURIZE IT</h3>
                  <p>Using a vapourizer is similar to smoking but without the combustion. Vapourizers come in many sizes and can cost anywhere from around $60 to $500. </p>
                </div>
                <div>
                  <h3>EAT IT</h3>
                  <p>Selling edibles is not legal in Canada, but you can make your own using cannabis or cannabis oils. Be sure to do your research first on preparing foods with cannabis, and take care to ensure nobody unwittingly eats it. </p>
                </div>
              </div>
            </div>
          </section>
          <section id='journal'>
            <img src={JournalIcon} alt="journal" style={{marginBottom: '20px'}}></img>
            {!this.state.success && <h3>SPECIAL OFFER</h3>}
            <h1>{!this.state.success ? <span>Get the<br/>Tracking Journal.</span> : 'Thank you!'}</h1>
            <p>{!this.state.success ? 'Sign up and receive your free cannabis tracking journal from Sundial.' : 'A tracking journal will be sent to the address you provided.'}</p>
            {!this.state.success && this.state.errorResponse !== '' && <div className='error-container'>
              <p>{this.state.errorResponse}</p>
            </div>}

            {!this.state.success && <div className='input-fields-container'>
              <InputComponent
                title="FIRST NAME"
                placeholder="First"
                id="firstName"
                onChange={this.onChange}
                value={this.state.firstName}
                autovalidate={this.state.autoValidate}
                validations={cannotBeEmptyValidationConditions}
              />
              <InputComponent
                title="LAST NAME"
                placeholder="Last"
                id="lastName"
                onChange={this.onChange}
                value={this.state.lastName}
                autovalidate={this.state.autoValidate}
                validations={cannotBeEmptyValidationConditions}
              />
              <InputComponent
                title="ADDRESS"
                placeholder="Address"
                id="address"
                onChange={this.onChange}
                value={this.state.address}
                autovalidate={this.state.autoValidate}
                validations={cannotBeEmptyValidationConditions}
              />
              <InputComponent
                title="CITY"
                placeholder="City"
                id="city"
                inputClass={"med-field"}
                onChange={this.onChange}
                value={this.state.city}
                autovalidate={this.state.autoValidate}
                validations={cannotBeEmptyValidationConditions}
              />
              <InputComponent
                title="PROVINCE"
                placeholder="Province"
                id="province"
                inputClass={'small-field tablet-field-fifty'}
                onChange={this.onChange}
                value={this.state.province}
                autovalidate={this.state.autoValidate}
                validations={cannotBeEmptyValidationConditions}
              />
              <InputComponent
                title="POSTAL CODE"
                placeholder="Postal Code"
                id="postalCode"
                inputClass={'med-field tablet-field-fifty'}
                onChange={this.onChange}
                value={this.state.postalCode}
                autovalidate={this.state.autoValidate}
                validations={cannotBeEmptyValidationConditions}
              />
              <InputComponent
                title="EMAIL"
                placeholder="your@email.here"
                id="email"
                onChange={this.onChange}
                value={this.state.email}
                autovalidate={this.state.autoValidate}
                validations={newsLetterEmailValidationConditions}
              />
              <div className='submit-container'>
                <div className='white-submit-button' onClick={this.onSubmit}>
                  {this.state.loading ? <span className='spinner'></span> : 'SIGN UP'}
                </div>
              </div>
              <CheckboxComponent
                className='checkbox_white'
                id="ageVerified"
                validations={confirmOver18Conditions}
                text="I confirm that I am of age to legally consume cannabis in my province (19+, or 18+ in Alberta and Quebec), and a resident of Canada."
                checked={this.state.ageVerified}
                onChange={this.onChange}
              />
              <CheckboxComponent
                className='checkbox_white'
                id="receiveMarketing"
                validations={confirmOver18Conditions}
                text="I agree to receive marketing emails from Sundial and accept Sundial's web terms of use, privacy and cookie policy."
                checked={this.state.receiveMarketing}
                onChange={this.onChange}
              />
            </div>}
            
          </section>
          <div id='footer'>
            <hr/>
            <div className='footer-container'>
              <div>
                <a href='https://www.sundialcannabis.com/privacy' target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                <p> © 2018 Sundial Growers Inc. </p>

              </div>
              <div>
                <a href="https://www.sundialcannabis.com">Visit sundialcannabis.com</a>
              </div>
              <div>
                <a href="https://facebook.com/sundialcommunity/" rel="noopener noreferrer">
                  <img src={FacebookIcon} alt="facebook"></img>
                </a>
                <a href="http://instagram.com/sundialcannabis/" rel="noopener noreferrer">
                  <img src={InstagramIcon} alt="instagram"></img>
                </a>
                <a href="http://twitter.com/sundialcannabis" rel="noopener noreferrer">
                  <img src={TwitterIcon} alt="twitter"></img>
                </a>
              </div>
            </div>
          </div>
        </div>
    )
  }
}
