import React from 'react';

export default class Slider extends React.Component {
	state = {
		activeItem: 0,
		currentIndex: 0,
		maxChildren: 0,
		touchXStart: 0,
		disableMove: false
	}
	componentDidMount() {
		this.mapPropsToState()
	}
	mapPropsToState() {
		this.setState({
			maxChildren: React.Children.count(this.props.children)
		})
	}
	setActiveItem = (index) => {
		let ind = index
		if(index < 0) {
			ind = this.state.maxChildren - 1
		} else if(index > this.state.maxChildren - 1) {
			ind = 0
		}

    this.setState({
			activeItem: ind,
			currentIndex: ind
		});
	}
	onTouchStart = (e) => {
		this.setState({
			touchXStart: e.targetTouches[0].clientX
		})
	}
	onTouchMove = (e) => {
		if(e === undefined) { return }
		const touchX = e.targetTouches[0].clientX
		if(!this.state.disableMove){
			if(this.state.touchXStart - touchX > 50 && this.state.currentIndex !== this.state.maxChildren - 1) {
				//Scrollin left,
				this.setState((prevState) => {
					return {
						disableMove: true,
						currentIndex: prevState.currentIndex + 1,
						activeItem: prevState.activeItem + 1,
						touchXStart: 0
					}
				})
			} else if(this.state.touchXStart - touchX < -50 && this.state.currentIndex !== 0) {
				this.setState((prevState) => {
					return {
						disableMove: true,
						currentIndex: prevState.currentIndex - 1,
						activeItem: prevState.activeItem - 1,
						touchXStart: 0
					}
				})
			}
		}
		
	}
	onTouchEnd = (e) => {
		this.setState({
			disableMove: false
		})
	}

	render () {
		var innerStyle = `${typeof window === 'undefined' ? 0 : ((this.state.activeItem * window.innerWidth)) * -1}px`
		var newChildren = React.Children.map(this.props.children, function(child) {
			return React.cloneElement(child, { style: {left: `${innerStyle}`}});
		});

  return (<div className='max-width' className={this.props.className !== undefined ? this.props.className : ''}>
		<div 
			className='slide-container'
			onMouseDown={this.onTouchStart}
			onTouchStart={this.onTouchStart}
			onMouseMove={this.onTouchMove} 
			onTouchMove={this.onTouchMove}
			onMouseUp={this.onTouchEnd}
			onTouchEnd={this.onTouchEnd}>
				{newChildren}
    </div>
		<div className={`arrow left ${this.state.activeItem === 0 && 'hidden'}`} onClick={() => this.setActiveItem(this.state.currentIndex - 1)}/>
		<div className={`arrow right ${this.state.activeItem === this.state.maxChildren - 1 && 'hidden'}`} onClick={() => this.setActiveItem(this.state.currentIndex + 1)}/>
		<div className='progress-dots'>
			{this.props.children.map((value, key) => {
				return (<div key={key} className={`progress-dot ${this.state.activeItem === key ? 'active-dot' : ''}`}></div>)
			})}
		</div>
	</div>
		)
	}
}