export const sundialURL = "https://www.sundialcannabis.com/Umbraco/api/NewsletterSignup/NewsletterSignupAsync";
export const listId = "81d1b3db89"

export const emailValidation = function(email) {
	var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email.toLowerCase());
}

export const newsLetterEmailValidationConditions = [
	{
			message: 'This field is required and cannot be left blank. Please try again.',
			condition: /^(?!\s*$).+/
	},
	{
			message: 'Please enter a valid email address',
			condition: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	}
]

export const cannotBeEmptyValidationConditions = [
	{
			message: 'This field is required and cannot be left blank. Please try again.',
			condition: /^(?!\s*$).+/
	}
];

export const confirmOver18Conditions = [
	{
			message: 'Please confirm that you are over 18 in order to create an account.',
			condition: /^(?!\s*$).+/
	}
];