import React from 'react';

export default class CheckboxComponent extends React.Component {

	UNSAFE_componentWillReceiveProps(nextProps) {
		let messages = []
		let localValid = true;
		if (nextProps.checked !== this.props.checked || nextProps.invalidState !== this.props.invalidState) {
			if(nextProps.validations !== undefined) {
				nextProps.validations.forEach((val) => {
					if(!val.condition.test(nextProps.checked)) {
						messages.push(val.message)
						localValid = false
					}
				})
				this.setState({
					messages: messages,
					valid: localValid
				})
				if(this.props.validationCallback) {
					this.props.validationCallback(this.props.id, localValid)
				}
			}
		}
	}
	render() {
		return(
			<div className={`${this.props.className ? this.props.className : ''}`} ref={ref => this.wrapperRef = ref}>
				<label className="control control--checkbox">
					{this.props.text}
					<input 
						checked={this.props.checked} 
						id={this.props.id ? this.props.id : ''} 
						onChange={this.props.onChange ? this.props.onChange : () => {}}
						type="checkbox"
						/>
					<div className="control__indicator"/>
				</label>
			</div>
		)
	}
}