import React from 'react';
import SelectComponent from './SelectComponent';
import CheckboxComponent from './CheckboxComponent';
import axios from 'axios';
import Logo from '../images/Sundial_Logo_Green.svg';

export default class AgeVerification extends React.Component {
    constructor(props) {
        super(props);
        this.arrayOfYears = [];
        this.arrayOfDays = [];
        let dayStart = 1;
        let yearStart = 2000;
        while ( yearStart > 1899) {
            this.arrayOfYears.push(yearStart);
            yearStart -= 1;
        }
        while ( dayStart <= 31) {
            this.arrayOfDays.push(dayStart);
            dayStart += 1;
        }
    }

    state = {
        province: '',
        dateOfBirthMonth: '',
        dateOfBirthYear: '',
        dateOfBirthDay: '',
        invalid: false,
        rememberMe: false,
        hideContainer: false,
        underage: false
    }
    
    componentDidMount() {
       
        //check session storage and local storage
        if (sessionStorage.getItem('hasBeenAgeVerified')) {
            //If this is true means it has been stored to session.
            this.hideSelf();
        } else if (localStorage.getItem('hasBeenAgeVerified')) {
            this.hideSelf();
        } else {
            this.checkLocation();
        }
        //Check if user has already verified/and has remembered.
        
    }
    checkLocation = () => {
        const self = this;
        axios.get('https://api.ipify.org?format=json').then((response) => {
            if (response.data.ip !== undefined) {
                axios.get(`https://ipapi.co/${response.data.ip}/json/ `).then((response) => {
                    self.setState({
                        province: response.data.region
                    });
                });
            }
        }).catch(() => {});
    }
    hideSelf = () => {
        this.setState({
            hideContainer: true
        });
    }
    customChangeEvent = (val, id) => {
        this.setState({ [id]: val});
    }
    customCheckEvent = () => {
        
        this.setState((prevState) => {
            return {
                rememberMe: !prevState.rememberMe
            };
        });
    }
    checkAge = (status) => {
        if(this.state.province !== '' && status === 'yes') {
            //TODO: Handle age
            if(this.state.rememberMe) {
                //Save to Local storage
                localStorage.setItem('hasBeenAgeVerified', true);
                this.setState({
                    hideContainer: true
                });
            } else {
                //Save to session storage.
                sessionStorage.setItem('hasBeenAgeVerified', true);
                this.setState({
                    hideContainer: true
                });
            }
        } else {
            if(this.state.province === '') {
                this.setState({
                    invalid: true
                }); 
            } else {
                //Selected NO show bad screen
                this.setState({
                    underage: true
                });
            }
            
        }
    }
    render() {
        
        const provinceOptions = [
            'Alberta',
            'British Columbia',
            'Manitoba',
            'New Brunswick',
            'Newfoundland and Labrador',
            'Northwest Territories',
            'Nova Scotia',
            'Nunavut',
            'Ontario',
            'Prince Edward Island',
            'Quebec',
            'Saskatchewan',
            'Yukon'
        ];
        if (this.state.hideContainer) {
            return ('');
        }
        return (
            
            <div className="age-verification-overlay">
                <div className="blur-background"/>
                
                <div className="age-verification-container">
                    <img src={Logo}/>
                    <h1 className="age-title"> {this.state.underage ? 'Sorry' : 'Welcome!'}</h1>
                    <p className="responsibility-disclaimer">{ this.state.underage ? `You must be ${this.state.province === 'Alberta' || this.state.province === 'Quebec' ? '18' : '19'} or over to view this website.` : 'You must be of legal age in accordance with the law of your province to access this website.'}</p>
                    {!this.state.underage && 
                    <SelectComponent
                        id="province"
                        title="WHERE DO YOU LIVE?"
                        selectText={this.state.province !== '' ? this.state.province : 'Province'}
                        isGray={this.state.province === ''}
                        options={provinceOptions}
                        invalid={this.state.province === '' && this.state.invalid}
                        errorText={'Please select a province'}
                        itemSelect={(val, id) => this.customChangeEvent(val, id)}
                    />}
                    {!this.state.underage && 
                    <div className="age-acceptable-container">
                        <h3>{this.state.province === 'Alberta' || this.setState.province === 'Quebec' ? 'ARE YOU 18+?' : 'ARE YOU 19+?'}</h3>
                        <div className="yes-no-container">
                            <div className="submit-button" onClick={() => this.checkAge('no')}>NO</div>
                            <div className="submit-button" onClick={() => this.checkAge('yes')}>YES</div>
                        </div>
                    </div>
                    }
                    {!this.state.underage && 
                    <div className="remember-me-container">
                        <CheckboxComponent
                            text="Remember Me"
                            checked={this.state.rememberMe}
                            className="checkbox_green"
                            id="rememberMe"
                            onChange={() => this.customCheckEvent()}
                        />
                    </div>
                    }
                    {!this.state.underage && 
                    <div className="policy-agreement-container">
                        By entering this site you agree to our <br/>
                        <a href="https://www.sundialcannabis.com/terms">TERMS &amp; CONDITIONS</a> &nbsp; and &nbsp; <a href="https://www.sundialcannabis.com/privacy">PRIVACY POLICY</a>
                    </div>
                    }
                </div>
            </div>
        );
    }

}