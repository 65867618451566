import React from 'react';
import checkmark from '../images/checkmark_gray.png';

class SelectComponent extends React.Component {
    state = {
        optionWidth: 100
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('resize', this.setOptionWidth);
        this.setOptionWidth();
        
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.setOptionWidth);
    }

    setOptionWidth = () => {
        // let selectionbox = document.getElementById('selection-box');
        // let offsetWidth = selectionbox.offsetWidth;
        let {  offsetWidth } = this.refs.selectionBox;
        this.setState({
            optionWidth: offsetWidth
        });
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.options !== undefined && this.options.classList !== undefined && this.options.classList.contains('show')) {
                this.options.classList.remove('show');
                this.options.classList.add('hidden-select');
            }
        }
    }

    onSelect = () => {
        if (this.options) {
            this.options.classList.toggle('hidden-selct');
            this.options.classList.toggle('show');
        }
        this.setOptionWidth();
    }

    getWidthOfOptions = () => {
        if (this.selectionBox) {
            return React.ReactDOM.findDOMNode(this.refs.selectionBox).getBoundingClientRect().offsetWidth;
        } else {
            return 0;
        }
    }
    handleKeyPress = (event) => {
        if(event.key == 'Enter') {
            
            this.onSelect();
        }
    }
    selectItemOnPress = (event, option) => {
        if(event.key == 'Enter') {
            this.props.itemSelect(option, this.props.id);
            this.onSelect();
        }
    }
    render () {
        return (
            <div  className={`selection-container ${this.props.className || ''}`} ref={ref => this.wrapperRef = ref}>
                <h2 className="select-title">{this.props.title}</h2>
                <div id="selection-box" onKeyPress={this.handleKeyPress} tabIndex={0} ref="selectionBox" className={`select-box ${this.props.invalid ? 'field__invalid' : ''}`} onClick={this.onSelect}>
                    <h3 className={this.props.isGray ? 'gray-text' : ''}>{this.props.selectText}</h3>
                    <div className="arrow-box">
                        <span className="arrow-select black-arrow" />
                    </div>
                </div>
                <div  ref={ref => this.options = ref} id="options" className={'hidden-select selection-options'}>
                    {this.props.options.map((option, key) => {
                        return (
                            <div tabIndex={0} style={{ width: `${this.state.optionWidth - 1.75}px` }} className="select-option" key={key} onKeyPress={(event) => this.selectItemOnPress(event, option)} onClick={() => {
                                this.props.itemSelect(option, this.props.id);
                                this.onSelect();
                            }}
                            >
                                <h3>{option}</h3>
                                <img className={`checkmark ${option === this.props.selectText ? 'show' : 'hidden-select'} `} src={checkmark} />
                            </div>
                        );
                    }, this)}

                </div>
                {this.props.invalid ? <p className="invalid-field"> {this.props.errorText}</p> : ''}
            </div>
        );

    }

}

export default SelectComponent;
SelectComponent.displayName = 'SelectComponent';