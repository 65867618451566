import React from 'react';

class InputComponent extends React.Component {
    state = {
        valid: true,
        messages: []
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.autovalidate){
            this.validate(this.props, true);
        }
    }

    handleBlur = () => {
        this.validate(this.props, true);
    }

    validate = (nextProps, strict) => {
        let messages = [];
        let localValid = true;
        if (strict || (nextProps.value !== this.props.value || nextProps.invalidState !== this.props.invalidState)) {
            if(nextProps.validations !== undefined) {
                nextProps.validations.forEach(function(val) {
                    if(!val.condition.test(nextProps.value)) {
                        messages.push(val.message);
                        localValid = false;
                    }
                });
                this.setState({
                    messages: messages,
                    valid: localValid
                });
                if(this.props.validationCallback){
                    this.props.validationCallback(this.props.id, localValid, messages);
                }
            }
        }
    }

    handleKeyUp = (e) => {
        if(this.props.uppercase){
            var start = e.target.selectionStart;
            var end = e.target.selectionEnd;
            e.target.value = e.target.value.toUpperCase();
            e.target.setSelectionRange(start, end);
        }

        if(this.props.onKeyPress){
            this.props.onKeyPress(e);
        }
    }

    render() {
        const { onChange, id, type, value, maxLength, pattern, disabled, placeholder, autoCorrect, spellCheck, autoComplete } = this.props;
        const massage = this.state.messages.length > 0 ? this.state.messages[0] : '';
        return (
            <div className={`input-container ${this.props.inputClass || ''}`} ref={ref => this.wrapperRef = ref}>
                <h2 className="select-title" style={{ color: this.props.titleColor ? this.props.titleColor : '' }}>{this.props.title}{this.props.optionalText ? <span className="input-optional-text">{this.props.optionalText}</span> : ''}</h2>
                {this.props.type || this.props.type === 'password' ?
                    <input {...{ onChange, id, type, value, maxLength, pattern, disabled, placeholder, autoCorrect, spellCheck, autoComplete }}  onKeyUp={this.handleKeyUp} onBlur={this.handleBlur} className={`input-view pt0 ${!this.state.valid ? 'field__invalid' : ''} ${this.props.className ? this.props.className : ''}`} /> :
                    <textarea {...{ onChange, id, type, value, maxLength, pattern, disabled, placeholder, autoCorrect, spellCheck, autoComplete }} onKeyUp={this.handleKeyUp} onBlur={this.handleBlur} className={`input-view ${!this.state.valid ? 'field__invalid' : ''} ${this.props.className ? this.props.className : ''}`} />}
                {!this.state.valid && !this.props.externalValidationMessage ? <p className="invalid-field mb0">{massage}</p> : ''}
            </div>
        );
    }
}

export default InputComponent;
InputComponent.displayName = 'InputComponent';