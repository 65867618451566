import React from 'react';
import SundialLogo from '../images/Sundial_Logo_Light.svg'
export default class Header extends React.Component {
	render() {
		return (
			<div className='sundial-header'>
				<a href="https://www.sundialgrowers.com" rel="noopener noreferrer" target="_blank"><img src={SundialLogo} /></a>
				<div className='sign-up-container'>
					<p>WANT A FREE TRACKING JOURNAL?</p>
					<a href="/#journal" className='white-button'>SIGN UP</a>
				</div>
			</div>
		)
	}
}